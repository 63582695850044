.icicc {
  text-align: center;
  font-size: 18px;  
}

.links {
  text-decoration: none;
  color: black;
}

.headerLogo {
  height: 100px;
  width: 100px;
  margin: 10px;
}

.breakText {
  display: inline-block;     
  max-width: 100px;          
  word-wrap: break-word;     
  white-space: normal;       
  line-height: 1.4;          
  text-align: center;        
}
.breakText2 {
  display: inline-block;     
  max-width: 100px;          
  word-wrap: break-word;     
  white-space: normal;       
  line-height: 1.4;          
  text-align: center;
          
}
.headerCont {
  display: flex;
  justify-content: space-around;
}

.organiser {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}

.dates {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
}

.container {
  margin: 20px;
  font-size: 15px;
}

.greet {
  font-weight: 500;
}

.highlight {
  font-weight: 600;
}

.caroHeight {
  height: 300px;
}

.car-img {
  width: 100%;
  height: 500px;
}
.box{
  display: flex;
  justify-content: space-around;
  /* flex-wrap: wrap;        */
  margin: 10px;
}
@media (max-width:500px) {
  .box{
    display: block;
  }
}

.alert {
  margin: 10px;
}

.alertContent {
  font-size: 20px;
  display: block;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
}

.flex {
  display: flex;
  justify-content: space-around;
}

.marContainer {
  border: 1px solid black;
  text-align: center;
  padding: 20px;
  margin: 30px 0;
  height: 500px;
}
.colorDate{
  color:black;
}

.marquee {
  height: 300px;
  width: 30vw;
  padding: 10px;
  margin: 30px 0;
}

.abtHeader {
  margin: 20px 40px;
}

.abtContent {
  width: 70vw;
  margin-top: 20px;
  text-align: justify;
}
.downloads{
  width:20vw;
}

.abtInstCont {
  width: 60vw;
  margin-top: 20px;
  margin-left: 20px;
  text-align: justify;
}

.logo {
  width: 25px;
  height: 25px;
  margin: 0 5px;
  color: white;
}

.bg {
  background-color: #3a66b8;
  color: white;
  padding: 50px;
  text-align: center;
}

.flex {
  display: flex;
  justify-content: space-around;
}

.awardAlert {
  background-color: #d9edf7 !important;
  border-radius: 5px;
  padding: 5px;
  min-height: 140px;
  height: auto;
}

.indAlert {
  background-color: #d9edf7 !important;
  border-radius: 5px;
  padding: 10px;
  min-height: 120px;
  height: auto;
}

.alertCont {
  margin: 0 0 1em;
  font-size: 15px;
  line-height: 1.2em;
  color: black;

}
.indCont {
  margin: 0 0 1em;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2em;
  color: black;

}

.dCont {
  display: flex;
  flex-wrap: wrap;       
  margin: 10px;
}

.dCont>* {
  flex: 1 1 160px;
  margin: 20px;
}

.downloadBadges {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  background-color: #3a66b8 !important;
  padding: 10px;
  margin: 6px;
  border-radius: 5px;
  color: white;
  height:10vh;
  width: 20vw;
}

.icon {
  height: 15px;
  width: 20px;
  margin: 5px;
  color: "white";
}

.techMem {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  height: auto;
  width: auto;
  background-color: #3a66b8 !important;
  border-radius: 5px;
  color: white !important;
}

.spMem {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  height: auto;
  max-width: 400px;
  background-color: #3a66b8 !important;
  border-radius: 5px;
  color: white !important;
}

.pubMem {
  height: auto;
  width: auto !important;  
  color: black !important;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -20px;
}
.pubMem>li{
  list-style: none;
  flex: 0 0 33.333333%;
}

.advMem {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  height: auto;
  background-color: #3a66b8 !important;
  border-radius: 5px;
  color: white !important;
}

.spCont {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* align-items: center; */
  margin: 10px;
  width: 70vw;
  background-color: #edf6fa;
  padding: 40px;
  margin: 30px;
}

.spCont>* {
  flex: 1 1 160px;
  margin: 5px 20px;

}
.publications{
  background-color:#edf6fa;
  padding:40px;
  margin:30px;
}

.pubCont {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 10px;
  background-color: #edf6fa;
  padding: 40px;

}
.pubCont>img{
  width:145px;
  height:90px;
  margin:5px;
}
.techCont {
  display: flex;
  flex-wrap: wrap;
  margin: 10px;
  width: 70vw;
}

.techCont>* {
  flex: 1 1 160px;
  margin: 5px 20px;

}

.awardsCont {
  padding: 50px;
}
.volumes{
  width:60vw;
  margin:auto;
}

.table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 70%;
}

.table>td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.paymentCont {
  border: 1px solid black;
  padding: 20px;
  width: 80vw;
  margin: 20px 10px;
  text-align: center;
}

.paymentBtn {
  background: #3498db;
  -webkit-border-radius: 10;
  -moz-border-radius: 10;
  border-radius: 8px;
  font-family: Arial;
  color: white;
  font-size: 20px;
  padding: 5px 28px 5px 28px;
  text-decoration: none;
  border: none;
}

.paymentBtn:hover {
  background: #3cb0fd;
  background-image: -webkit-linear-gradient(top, #3cb0fd, #3498db);
  background-image: -moz-linear-gradient(top, #3cb0fd, #3498db);
  background-image: -ms-linear-gradient(top, #3cb0fd, #3498db);
  background-image: -o-linear-gradient(top, #3cb0fd, #3498db);
  background-image: linear-gradient(to bottom, #3cb0fd, #3498db);
  text-decoration: none;
}
.venueImage{
  width:60vw;
  height:50vh;
}
.logoCont{
  display: flex;

}
.navLink{
  margin:10px 10px;
  color: black !important;
}
.navDD{
  margin:2px;
  word-wrap: break-word;
  color: black !important;
}
@media (max-width:500px) {
  .headerCont {
    display: block;
 
    /* justify-content: space-around; */
  }

  .headerLogo {
    height: 80px;
    width: 80px;
    margin: 10px;
  }
  .navLink{
    margin:5px 10px;
    color: black, import !important;
  }
  .navDD{
    margin:-3px 10px;
    color: black, import !important;
  }

  .flex{
    display: block;
  }
  .marContainer {
    border: 1px solid black;
    /* width:80vw; */
    text-align: center;
    padding: 10px;
    margin: 30px 5px;
    height: auto;
  }
  .marquee {
    height:300px;
    width:90%!important;
    padding: 0px;
    margin: 30px 0;
  }
  .alertContent {
    font-size: 15px;
    display: block;
    text-align: center;
    text-decoration: none;
    font-weight: 600;
  }
  .abtHeader {
    margin: 20px 10px;
  }
  
  .abtContent {
    width: 90vw;
    /* padding:10px; */
    margin: 15px;
    text-align: justify;
  }
  .downloads{
    width:100%;
    margin: 15px;
  }
  .abtContent {
    width: 90vw;
  }
  .abtInstCont {
    width: 90vw;
  }
  
.spCont {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0px;
  width: 100vw;
  background-color: #edf6fa;
  padding: 40px;

}

.spCont>* {
  flex: 1 1 160px;
  margin: 5px 20px;
}
.publications{
  background-color:#edf6fa;
  padding:20px;
  margin:30px;
}
.pubCont {
  display: flex;
  width:100%;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: #edf6fa;
  margin: 0 10px;
}
.techCont{
  width:100vw;
  margin:0;
}
.awardsCont {
  padding: 20px;
}
.venueImage{
  position:block;
  width:95vw;
  height:30vh;
  margin:0 2.5vw;
}
.volumes{
  width:100%;
  margin:0;
}
.downloadBadges {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  background-color: #3a66b8 !important;
  padding: 10px;
  border-radius: 5px;
  color: white;
  width: 80vw;
  /* margin:10px auto; */
}
.mobile{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

}