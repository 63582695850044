.OrgAssPartners {
    margin: 2rem auto;
    background-color: #fff;
    padding: 1rem;
    border-radius: 10px;
    width: 92%;

}
.publicationContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.publicationContainer img {
    margin: 1rem;
    height: 20vw;
    max-height: 100px;
    min-height: 100px;
}
.partnerContainer{
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top:1.5rem;
    border: 2px solid #231834;
    border-radius: 5px;
        box-shadow: 7px 7px grey;
        width:15rem;
        height:13rem;

    }
    .flex-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        max-width: 20rem;
    }
    .flex-container img {
        align-items: center;
        width: 13rem;
        height: auto;
        margin: 10px;
        
    }



.speaker21 {
    width: 220px;
    height: 350px;
    text-align: center;
    padding: 10px;
    margin: 10px;
    vertical-align: super;
    box-shadow: 2px 1px 3px 3px #eee;
}

.speaker21 p {
    margin-top: 10px;
}

.speaker21 p span {
    display: block;
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes reverseMarquee {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.keynote-speakers {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .tab-button {
    padding: 10px 20px;
    margin: 10px 10px;
    border: none;
    background-color: #ddd;
    cursor: pointer;
  }
  
  .tab-button.active {
    background-color: #007bff;
    color: white;
  }
  
  .cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
  }
  
  .card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    text-align: center;
    padding: 10px;
  }
  
  .card-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }
  
  .card-desc {
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
  }
  
  @media (max-width:500px) {
    .tabs {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      margin-bottom: 20px;
    }
  }