/* Container Styling */
.guidelines-container {
    max-width: 80vw;
    margin: 0 auto;
    padding: 40px 20px;
    line-height: 1.8;
    font-family: Arial, sans-serif;
    color: #333;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  }
  
  /* Header Styling */
  .guidelines-header {
    font-size: 2rem;
    text-align: center;
    /* color: #1e3a8a; */
    margin-bottom: 30px;
  }
  
  /* Section Styling */
  .guideline-section {
    margin-bottom: 30px;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease-in-out;
  }
  
  .guideline-section:hover {
    transform: translateY(-5px);
  }
  
  .guideline-section h2 {
    font-size: 1.3rem;
    /* color: #1e40af; */
    margin-bottom: 15px;
  }
  
  .guideline-section ul {
    padding-left: 20px;
    list-style-type: disc;
  }
  
  .guideline-section li {
    font-size: 1rem;
    line-height: 1.6;
    color: #555;
  }
  
  /* Link Styling */
  .guideline-section a {
    color: #007BFF;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .guideline-section a:hover {
    color: #0056b3;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .guidelines-container {
    width: 100vw;
      padding: 20px;
    }
  
    .guidelines-header {
      font-size: 1.5rem;
    }
  
    .guideline-section h2 {
      font-size: 1.2rem;
    }
  
    .guideline-section li {
      font-size: 0.9rem;
    }
  }
  